import { SettingApi } from './setting-api';
import http from './http';
import { ApiResponse } from '@/common/domain/entities/api-response';
import axios, { AxiosError } from 'axios';
import { FullUserBackofficeModel } from '../dto/user-backoffice-model';
import { UserUpdateModel } from 'features/authentication/data/dto/user-model';
import { StockStatusBackoffice } from '../dto/stock-status-backoffice-model';
import { ShipmentBackoffice } from '../dto/shipment-backoffice-model';
import { FilterParams } from './catelog-api';
import { serializeFormQuery } from 'common/utils/general.utils';
import { httpGet, httpPatch } from 'common/utils/http.utils';
import { CustomerGroupModel } from '../dto/customer-backoffice-model';

export default class SettingApiImpl implements SettingApi {
	apiService = http;

	async getUserProfile(): Promise<ApiResponse<FullUserBackofficeModel>> {
		try {
			const resp = await this.apiService.get('/users/my-profile');
			const data = resp.data as ApiResponse<FullUserBackofficeModel>;
			return Promise.resolve(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}

	async updateUserProfile(params: UserUpdateModel): Promise<ApiResponse<FullUserBackofficeModel>> {
		try {
			const resp = await this.apiService.patch('/users', { ...params });
			const data = resp.data as ApiResponse<FullUserBackofficeModel>;
			return Promise.resolve(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}

	async getStockStatuses(): Promise<ApiResponse<StockStatusBackoffice[]>> {
		try {
			const resp = await this.apiService.get('/stock-Statuses');
			const data = resp.data as ApiResponse<StockStatusBackoffice[]>;
			return Promise.resolve(data);
		} catch (err) {
			if (axios.isAxiosError(err)) {
				const errData = err as AxiosError;
				if (errData.response?.data) {
					const data = errData.response?.data as ApiResponse<void>;
					return Promise.reject(data);
				}
			}
			const error = { message: 'unexpected_error' } as ApiResponse<void>;
			return Promise.reject(error);
		}
	}

	async updateStockStatuses(params: StockStatusBackoffice):
			Promise<ApiResponse<StockStatusBackoffice>> {
		return httpPatch(`/stock-Statuses/${params.stockStatusId}`, {
			description: params.description,
			minValue: params.minValue,
			maxValue: params.maxValue,
		}, this.apiService);
	}

	async getShipment(params: FilterParams): Promise<ApiResponse<ShipmentBackoffice[]>> {
		const query = serializeFormQuery(params);
		return httpGet(`/shipments${query}`, this.apiService);
	}

	async updateShipment(params: ShipmentBackoffice): Promise<ApiResponse<ShipmentBackoffice>> {
		return httpPatch(`/shipments/${params.id}`, { ...params }, this.apiService);
	}

	async updateGroup(params: CustomerGroupModel): Promise<ApiResponse<CustomerGroupModel>> {
		return httpPatch(`/customer-groups/${params.id}`, {
			name: params.name,
			description: params.description,
			fixedValue: params.discount?.fixedValue,
			percentageValue: params.discount?.percentageValue,
			shouldPayShipmentFee: params.shouldPayShipmentFee,
		}, this.apiService);
	}
}
